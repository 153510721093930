mat-calendar.select-time {
  min-height: 500px;

  .mat-calendar-body-label {
    opacity: 0;
  }

  .mat-calendar-body-label[colspan='7'] {
    display: none;
  }
}
