html,
body {
  height: 100%;
  font-family: '"Source Sans Pro", Roboto, "Helvetica Neue", sans-serif';
}

body {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--scrollbar-color);
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-background-color);
}
