mat-stepper.main-stepper {
  $numberOfSteps: 3;

  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }

  @for $i from 0 through $numberOfSteps {
    &.last-edited-step-#{$i} {
      mat-step-header:nth-of-type(#{$i + 1}) {
        .mat-step-icon {
          background-color: var(--dark-white);
          color: var(--white-light1);
        }
      }

      .mat-horizontal-stepper-header-container {
        .mat-stepper-horizontal-line:nth-of-type(#{$i}) {
          background: var(--light-blue);
        }
      }
    }
  }

  &.last-edited-step-2 {
    .mat-horizontal-stepper-header-container {
      opacity: 0;
    }
  }

  .mat-horizontal-content-container {
    max-width: 1000px;
    margin: 0 auto;
    @media (max-width: 600px) {
      max-width: 100vw;
    }
  }

  .mat-horizontal-stepper-header-container {
    justify-content: center;
    border-bottom: 1px solid var(--border-gray);
    padding-bottom: 10px;
    margin-bottom: 50px;

    @media (max-width: 600px) {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;

      .mat-step-text-label {
        display: none;
      }
    }
  }

  .mat-horizontal-stepper-header {
    &::after,
    &::before {
      border-top-color: var(--white);
    }
  }

  .mat-stepper-horizontal-line {
    max-width: 166px;
    top: 46px;
    background: var(--white-light5);
    height: 4px;
    border: none;

    &:after {
      border-top-color: var(--white);
    }
  }

  mat-step-header {
    background: none !important;

    .mat-step-icon {
      height: 48px;
      width: 48px;
      background-color: var(--white-light5);
      color: var(--gray4);
      font-weight: bold;

      &.mat-step-icon-state-edit {
        background-color: var(--dark-white);
        color: var(--white-light1);
      }

      mat-icon {
        font-size: 19px;
        width: 19px;
        height: 19px;
        font-weight: bold;
      }
    }
  }
}
