.d-flex {
  display: flex;

  &.flex-row {
    flex-direction: row;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.flex-center {
    align-items: center;
    justify-content: center;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.flex-1 {
    flex: 1;
  }
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.box {
  &.border-box {
    box-sizing: border-box;
  }
}

.border {
  &.rounded-circle {
    border-radius: 50%;
  }

  @for $i from 1 through 100 {
    &.border-#{$i} {
      border-radius: #{$i}px;
    }
  }
}

.d-none {
  display: none !important;
}
