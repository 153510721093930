button.mat-dark {
  font-weight: 600;
  --mdc-text-button-label-text-color: white;

  border-radius: 100px;
  color: var(--white-light2);
  background-color: var(--white-light1);
}

button:disabled.mat-dark {
  background: rgba(0, 0, 0, 0.26);
}

.mat-secondary {
  background-color: (--white-light4);
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  border-radius: 32px !important;

  &.change {
    margin-bottom: 20px;
  }
}
