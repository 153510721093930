body {
  //Gray
  --border-gray: #e1e1e1;

  --white-light: #dddddd;
  --white-light1: #333333;
  --white-light2: #ffffff;
  --white-light3: #c4c4c4;
  --white-light4: #d9d9d9;
  --white-light5: #efefef;

  --white: #fff;
  --white-1: #fff8f0;

  --light-blue: #ddf1fa;

  --black1: #212529;
  --black2: rgba(47, 47, 47, 0.08);
  --gray: #626262;
  --gray1: #777777;
  --gray2: #838383;
  --gray3: #666666;
  --gray4: #848484;
  --gray5: #707070;

  --dark-white: #ebf7fc;
  --box-shadow: #5e5e5e;
  --red: #ff1010;
  --red1: #f44336;
  --red2: #990000;

  --orange: #e8912e;
}
