app-input {
  .app-input-form {
    width: 100%;

    --mdc-theme-error: var(--red1);

    mat-form-field {
      width: 100%;
    }

    mat-form-field .mat-mdc-form-field {
      width: 100%;
    }
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: var(--gray3);
  }
}
