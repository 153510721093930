// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '../../../node_modules/@angular/material/index' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './blue_theme';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette($blue-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

$my-typography: mat.define-typography-config(
  $font-family: '"Source Sans Pro", Roboto, "Helvetica Neue", sans-serif'
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
    typography: $my-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

@import 'components/mat-toolbar';
@import 'components/mat-button';
@import 'components/mat-stepper';
@import 'components/mat-calendar';

a {
  text-decoration: none;
  color: #001ec1;

  &:active {
    color: #001ec1;
  }
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6 {
  font-family: Montserrat, Roboto, 'Helvetica Neue', sans-serif !important;
  margin: 0;
}

mat-form-field.no-validation-area {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
